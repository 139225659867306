import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { database, ref, set, get } from '../firebaseConfig';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [userID, setUserID] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (username && password) {
      try {
        // Check if user already exists in Firebase Realtime Database
        const userRef = ref(database, 'users/' + username);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          // User exists, use the stored user ID
          const storedUserID = snapshot.val().userID;
          setUserID(storedUserID);
          toast.info(`Welcome back, ${username}! Your User ID is ${storedUserID}`, {
            onClose: () => {
              navigate('/mining', { state: { username, userID: storedUserID } });
            }
          });
        } else {
          // New user, generate a random user ID
          const newUserID = Math.floor(Math.random() * 100000000) + 1;
          setUserID(newUserID);

          // Store the new user data in Firebase with the optional referral code
          await set(ref(database, 'users/' + username), {
            userID: newUserID,
            username: username,
            referralCode: referralCode || null  // Store referral code if provided, else null
          });

          toast.success(`Login successful! Welcome, ${username}. Your User ID is ${newUserID}`, {
            onClose: () => {
              navigate('/mining', { state: { username, userID: newUserID } });
            }
          });
        }

        setIsLoggedIn(true);
      } catch (error) {
        toast.error('Error logging in: ' + error.message);
      }
    } else {
      toast.error('Please fill in both username and password.');
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <label>Telegram Username</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <label>Referral Code (optional)</label>
        <input
          type="text"
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
        />

        <button type="submit">Login</button>
      </form>

      {isLoggedIn && (
        <div className="user-info">
          <p><strong>Username:</strong> {username}</p>
          <p><strong>User ID:</strong> {userID}</p>
        </div>
      )}

      <ToastContainer position="top-center" />
    </div>
  );
}

export default Login;
