import '../App.css'; // Assuming your CSS is saved in App.css
import { BsShopWindow } from 'react-icons/bs';
import { FaUserFriends } from 'react-icons/fa';
import { SiSololearn } from 'react-icons/si';
import { MdSelfImprovement } from 'react-icons/md';
import { IoMenu } from "react-icons/io5";
import navlogo from "../bitslogo-removebg-preview.png";
import React, { useState, useEffect } from 'react';
import profileImg from '../profile_img.jpeg';

import img from "../images/1.jpeg"
import "./CityPage.css"


import { useTranslation } from 'react-i18next'; // Import the translation hook
import i18n from '../i18n'; // Import your i18n configuration


const CityPage = () => {


  const { t } = useTranslation(); // Use the translation function
  const [menuOpen, setMenuOpen] = useState(false);


  const [languageOpen, setLanguageOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');

  
  // List of languages (ISO 639-1 language names)
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'zh', name: 'Chinese' },
    { code: 'hi', name: 'Hindi' },
    { code: 'ar', name: 'Arabic' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'bn', name: 'Bengali' },
    { code: 'ru', name: 'Russian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'ko', name: 'Korean' },
    { code: 'it', name: 'Italian' },
    { code: 'nl', name: 'Dutch' },
    { code: 'tr', name: 'Turkish' },
    { code: 'sv', name: 'Swedish' },
    { code: 'pl', name: 'Polish' },
    { code: 'da', name: 'Danish' },
    { code: 'el', name: 'Greek' },
    { code: 'cs', name: 'Czech' },
    { code: 'he', name: 'Hebrew' },
    { code: 'id', name: 'Indonesian' },
    { code: 'th', name: 'Thai' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'ms', name: 'Malay' },
    { code: 'tl', name: 'Filipino' },
    { code: 'fi', name: 'Finnish' },
    { code: 'hu', name: 'Hungarian' },
    { code: 'no', name: 'Norwegian' },
    { code: 'uk', name: 'Ukrainian' },
    { code: 'ro', name: 'Romanian' },
    { code: 'ur', name: 'Urdu' },
    { code: 'sw', name: 'Swahili' },
    { code: 'ta', name: 'Tamil' },
    { code: 'te', name: 'Telugu' },
    { code: 'mr', name: 'Marathi' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'pa', name: 'Punjabi' },
    { code: 'fa', name: 'Persian' },
    { code: 'ps', name: 'Pashto' },
    { code: 'zu', name: 'Zulu' },
    { code: 'am', name: 'Amharic' },
    { code: 'ha', name: 'Hausa' },
    { code: 'so', name: 'Somali' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'lt', name: 'Lithuanian' },
    { code: 'lv', name: 'Latvian' },
    { code: 'et', name: 'Estonian' },
    { code: 'is', name: 'Icelandic' },
    { code: 'mk', name: 'Macedonian' }
  ];



  const games = [
    { title: 'Train Miner', img: '1.jpeg', gems: '0/12' },
    { title: 'Factory World', img: '2.jpeg', gems: '0/12' },
    { title: 'Count Masters', img: '3.jpeg', gems: '0/12' },
    { title: 'Snake Run', img: '4.png', gems: '0/4' },
    { title: 'Merge Away', img: '5.webp', gems: '0/4' },
    { title: 'Cooking Stories', img: '6.jpeg', gems: '0/4' },
    { title: 'Tower Defence', img: '7.jpeg', gems: '0/4' },
    { title: 'Infected Frontier', img: '8.jpeg', gems: '0/4' },
    { title: 'Bouncemasters', img: '9.jpeg', gems: '0/4' }
  ];

  const [activeTab, setActiveTab] = useState('Games');




  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleLanguageDropdown = () => {
    setLanguageOpen(!languageOpen);
  };

  const handleLanguageSelect = (langCode, langName) => {
    setSelectedLanguage(langName);
    setLanguageOpen(false);
    i18n.changeLanguage(langCode); // Change the language
  };









  return (
    <div className="game-container">
      {/* Main Content */}
      <div className='menu1'>
        <div className='profileImg'>
          <img src={profileImg} className='profile1'/>
          <span className='Imgtext'>Lv. 7</span>
          <span className='powerper'>5%</span>
        </div>
        <div className='HederCoin'>
          <div className='HeaderContain'>Coins per Hour</div>
          <img src={navlogo} alt="User Avatar" className="avatarcoin1" />
          <div className='percoin'>+225</div>
        </div>
        <IoMenu className="svg1" onClick={toggleMenu} />
        <div className={`side-menu ${menuOpen ? '' : 'open'}`}>
          <div className="menu-card">
            <ul>
              <li>{t('vibrate')}</li>
              <li>
                {t('language')}: {selectedLanguage}
                <button onClick={toggleLanguageDropdown} className="dropdown-btn">
                  {languageOpen ? '▲' : '▼'}
                </button>
                {languageOpen && (
                  <ul className="language-dropdown">
                    {languages.map(({ code, name }) => (
                      <li key={code} onClick={() => handleLanguageSelect(code, name)}>
                        {name}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li>
                <a href="https://t.me/+jM2JnbmvghU4Y2Fl" target="_blank" rel="noopener noreferrer">
                  {t('telegram')}
                </a>
              </li>
              <li>{t('support')}</li>
            </ul>
          </div>
        </div>
        </div>

        <div className="app">
      {/* Tabs */}
      <div className="tabs">
        {['Games', 'Minigames', 'Mine cards'].map(tab => (
          <button
            key={tab}
            className={activeTab === tab ? 'active' : ''}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Scrollable Game Grid */}
      <div className="scrollable-container">
        <div className="game-grid">
          {games.map((game, index) => (
            <div className="game-card" key={index}>
              <div className="image-container">
                <img src={require(`../images/${game.img}`)} alt={game.title} />
                <span className='commingsoon'>Comming Soon...</span>
              </div>
              <div className="game-info">
                <h3>{game.title}</h3>
                <p><i className="diamond-icon">💎</i>{game.gems}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
   
     
    </div>
  );
};

export default CityPage;
