import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';


// Import your page components
import ImprovePage from './pages/ImprovePage';
import CityPage from './pages/CityPage';
import MiningPage from './pages/MiningPage';
import FriendsPage from './pages/FriendsPage';
import QuestsPage from './pages/QuestsPage';
import AdminPage from "./pages/AdminPage";

import './App.css';
import { BsShopWindow } from 'react-icons/bs';
import { FaUserFriends } from 'react-icons/fa';
import { SiSololearn } from 'react-icons/si';
import { MdSelfImprovement } from 'react-icons/md';
import Login from './pages/Login';

import navlogo from './bitslogo-removebg-preview.png';

const Main = () => {
  const [activeMenu, setActiveMenu] = useState('improve'); // Manage active menu item state
  const [loading, setLoading] = useState(false); // Loading state
  const location = useLocation(); // Get current route location

  // Function to set the active state and show the loading spinner
  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    setLoading(true); // Show loading spinner on menu click
  };

 
  
  return (
    <div className="app-container">


      {/* Page Routes */}
      <Routes>
      <Route path="/" element={<Login />} />
        <Route path="/improve" element={<ImprovePage />} />
        <Route path="/city" element={<CityPage />} />
        <Route path="/mining" element={<MiningPage />} />
        <Route path="/friends" element={<FriendsPage />} />
        <Route path="/quests" element={<QuestsPage />} />
        <Route path="/admin" element={<AdminPage />} />
      </Routes>


 
      {/* Footer with Navigation Menu */}
      <footer className="footer">
        <div className="footer-menu">
          <Link to="/improve" className={activeMenu === 'improve' ? 'active' : ''} onClick={() => handleMenuClick('improve')}>
            <MdSelfImprovement /> Improve
          </Link>
          <Link to="/city" className={activeMenu === 'city' ? 'active' : ''} onClick={() => handleMenuClick('city')}>
            <BsShopWindow /> City
          </Link>
          <Link to="/mining" className={activeMenu === 'mining' ? 'active' : ''} onClick={() => handleMenuClick('mining')}>
            <span className="avatar1">
              <img src={navlogo} alt="Mining Avatar" className="avatar" />
              Mining
            </span>
          </Link>
          <Link to="/friends" className={activeMenu === 'friends' ? 'active' : ''} onClick={() => handleMenuClick('friends')}>
            <FaUserFriends /> Friends
          </Link>
          <Link to="/quests" className={activeMenu === 'quests' ? 'active' : ''} onClick={() => handleMenuClick('quests')}>
            <SiSololearn /> Quests
          </Link>
        </div>
      </footer>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  );
};

export default App;
