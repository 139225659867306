import '../App.css';
import { IoMenu } from "react-icons/io5";
import navlogo from "../bitslogo-removebg-preview.png";
import React, { useState, useEffect } from 'react';
import profileImg from '../profile_img.jpeg';
import { useNavigate } from "react-router-dom";
import wallet from "../assets/vicky123.png";
import "./QuestsPage.css";
import { database, ref, set, get, child,onValue } from '../firebaseConfig';



const QuestsPage = () => {

  const [username, setUsername] = useState('');

  const [address, setAddress] = useState('');
  const [coins, setCoins] = useState(0); // Set initial state for coins
  const [showInfo, setShowInfo] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [newAddress, setNewAddress] = useState('');

  useEffect(() => {
    // Retrieve wallet address from Firebase when component loads
    const fetchAddress = async () => {
      try {
        const dbRef = ref(database);
        const snapshot = await get(child(dbRef, `walletAddress`));
        if (snapshot.exists()) {
          setAddress(snapshot.val());
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    };

    // Fetch real-time coins data from Firebase
    const fetchCoins = () => {
      const coinsRef = ref(database, 'users/your-user-id/coins');
      onValue(coinsRef, (snapshot) => {
        if (snapshot.exists()) {
          setCoins(snapshot.val());
        } else {
          console.log("No coins data available");
        }
      });
    };

    fetchAddress();
    fetchCoins(); // Start listening for coins changes

    // No need to remove listeners since onValue will keep the listener active until the component is unmounted.
  }, []);

  // Function to handle Wallet logo click
  const handleWalletLogoClick = () => {
    setShowInfo(true); // Display the coins and address
  };

  // Function to handle Update Address button click
  const handleUpdateClick = () => {
    setShowUpdateForm(true); // Show the update address form
  };

  // Function to handle address update submission
  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    try {
      // Save the new address to Firebase
      await set(ref(database, 'walletAddress'), newAddress);
      setAddress(newAddress); // Update local state
      setNewAddress(''); // Clear the input field
      setShowUpdateForm(false); // Hide the update form
    } catch (error) {
      console.error("Error updating address:", error);
    }
  };

  // Helper function to truncate the address
  const truncateAddress = (addr) => {
    return addr ? `${addr.slice(0, 5)}...${addr.slice(-5)}` : '';
  };




   




  // Handle the Telegram login event after successful authentication


  return (
    <div className="game-container">
      <div className='menu1'>
        <div className='profileImg'>
          <img src={profileImg} className='profile1' alt="Profile" />
          <span className='Imgtext'>Lv. 7</span>
          <span className='powerper'>5%</span>
        </div>
        <div className='HederCoin'>
          <div className='HeaderContain'>Coins per Hour</div>
          <img src={navlogo} alt="User Avatar" className="avatarcoin1" />
          <div className='percoin'>+225</div>
        </div>
        <IoMenu className='svg1' />
      </div>

      <div className="wallet-card">
        {/* Wallet Logo */}
        <div className="wallet-logo" onClick={handleWalletLogoClick}>
          <img src={wallet} alt="Wallet Logo" />
        </div>

        <div className="wallet-options">
          <button className="wallet-button" onClick={handleWalletLogoClick}>
            Wallet
          </button>
          <button className="wallet-button restore" onClick={handleUpdateClick}>
            Update Address
          </button>
        </div>


        {/* Display address and coins */}
        {showInfo && (
          <div className="wallet-info">
            <p><strong>Address:</strong> {truncateAddress(address)}</p>
            <p><strong>Coins:</strong> {coins}</p>
          </div>
        )}

        {/* Update Address Form */}
        {showUpdateForm && (
          <div className="update-card">
            <h3>Update Wallet Address</h3>
            <form onSubmit={handleAddressSubmit}>
              <input
                type="text"
                placeholder="Enter new wallet address"
                value={newAddress}
                onChange={(e) => setNewAddress(e.target.value)}
                required
              />
              <button type="submit" className="submit-btn">Update</button>
            </form>
          </div>
        )}
      </div>

    


    </div>
  );
};

export default QuestsPage;
