import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { database, ref, set } from '../firebaseConfig';
import navlogo from "../bitslogo-removebg-preview.png";
import boy from "../man-removebg-preview__1_-removebg-preview.png";
import profileImg from '../profile_img.jpeg';
import hhuman from "../assets/hamster.png"
import { IoMenu } from "react-icons/io5";
import '../App.css';
import './Mining.css'

// Replace with actual avatar image paths
const avatars = [
  require('../assets/hamster.png'),
  require('../assets/love.jpeg'),
  require('../assets/love2.jpeg'),
  require('../assets/love3.png'),
  require('../assets/love4.jpeg'),
  require('../assets/love5.jpeg'),
  require('../assets/love6.jpeg'),
  require('../assets/love7.jpeg'),
  require('../assets/love8.jpeg'),





];

const MiningPage = () => {
  const location = useLocation();
  const { username, userID } = location.state || {};
  const [coins, setCoins] = useState(0);
  const [energy, setEnergy] = useState(1000);
  const [isCharging, setIsCharging] = useState(false);
  const [showFloatingText, setShowFloatingText] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showAvatarCard, setShowAvatarCard] = useState(false);
  const [currentAvatar, setCurrentAvatar] = useState(boy);

  const coinsPerTap = 1;
  const LOW_BATTERY_THRESHOLD = 100;
  const MAX_ENERGY = 1000;
  const CHARGE_AMOUNT = 1;

  // Function to handle coin taps
  const handleTap = () => {
    if (energy > 0) {
      const newCoinCount = coins + coinsPerTap;
      setCoins(newCoinCount);
      setEnergy(energy - 1);
      setShowFloatingText(true);
      setTimeout(() => setShowFloatingText(false), 1000);
    }
  };

  // Handle energy charging logic
  useEffect(() => {
    let chargeInterval;
    if (isCharging && energy < MAX_ENERGY) {
      chargeInterval = setInterval(() => {
        setEnergy((prevEnergy) => Math.min(prevEnergy + CHARGE_AMOUNT, MAX_ENERGY));
      }, 1000);
    }
    return () => {
      clearInterval(chargeInterval);
    };
  }, [isCharging, energy]);

  // Update Firebase in real time whenever coins or energy change
  useEffect(() => {
    if (username && userID) {
      const userRef = ref(database, `users/${username}`);
      set(userRef, { userID, coins, energy })
        .catch((error) => {
          console.error("Error updating Firebase:", error);
        });
    }
  }, [coins, energy, username, userID]);

  // Handle avatar selection
  const handleAvatarSelect = (avatar) => {
    setCurrentAvatar(avatar);
    setShowAvatarCard(false);
  };

  // Start/stop energy charging
  const toggleCharging = () => {
    setIsCharging(!isCharging);
  };

  return (
    <div className="game-container">
      <div className='menu1'>
        <div className='profileImg'>
          <img src={profileImg} className='profile1' alt="Profile"/>
          <span className='Imgtext'>Lv. 7</span>
          <span className='powerper'>5%</span>
        </div>
        <div className='HederCoin'>
          <div className='HeaderContain'>Coins per Hour</div>
          <img src={navlogo} alt="Coin Icon" className="avatarcoin1" />
          <div className='percoin'>+225</div>
        </div>
        <IoMenu className='svg1' />
      </div>

      <div className='coinsCount'>
        <img src={navlogo} alt="User Avatar" className="avatarcoin" />
        <span className='spnCount'>{coins}</span>
      </div>

      <div className='Aibutton'>
        <button 
          className="get-avatar-btn"
          onClick={() => setShowAvatarCard(!showAvatarCard)}
        >
          Get AI Avatar
        </button>
      </div>
      
      {showAvatarCard && (
        <div className="avatar-card">
          {avatars.map((avatar, index) => (
            <img
              key={index}
              src={avatar}
              alt={`Avatar ${index + 1}`}
              className="avatar-option"
              onClick={() => handleAvatarSelect(avatar)}
            />
          ))}
        </div>
      )}

      <div className="character-section">
        <img 
          src={currentAvatar} 
          alt="Character" 
          className={`character-img ${isHovered ? 'hovered' : ''}`} 
          onMouseEnter={() => setIsHovered(true)} 
          onMouseLeave={() => setIsHovered(false)} 
          onClick={handleTap}
        />
        {showFloatingText && (
          <div className="floating-text">+{coinsPerTap}</div>
        )}
      </div>

      <div className="battery-container">
        <div className="battery-vertical">
          <div
            className="battery-level-vertical"
            style={{
              height: `${(energy / MAX_ENERGY) * 100}%`,
              backgroundColor: energy <= LOW_BATTERY_THRESHOLD ? 'red' : 'green',
            }}
          ></div>
          <div className="battery-tip-vertical"></div>
        </div>
        <div className="battery-text">{energy}</div>
      </div>

      <div className="charging-toggle">
        <button onClick={toggleCharging}>
          {isCharging ? "Stop Charging" : "Start Charging"}
        </button>
      </div>
    </div>
  );
};

export default MiningPage;
