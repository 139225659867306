import '../App.css'; // Assuming your CSS is saved in App.css
import { IoMenu } from "react-icons/io5";
import navlogo from "../bitslogo-removebg-preview.png";
import React, { useState } from 'react';
import profileImg from '../profile_img.jpeg';
import "./FriendsPage.css";
import gift  from "../r-removebg-preview.png"
const FriendsPage = () => {
  const [linkCopied, setLinkCopied] = useState(false);
  const inviteLink = 'https://gamebts.com/'; // Replace with your actual invite link

  // Function to handle sharing via the Web Share API
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Invite a Friend',
          text: 'Please draw me  a prize and you can  claim the  surprise with me. I am  entering the  BTSTAP airdrop for free 5 Ton , 100% real gift !',
          url: inviteLink,
        });
        console.log('Successful share');
      } catch (error) {
        console.error('Error sharing:', error);
        alert('Error sharing the invite. Please try again.');
      }
    } else {
      alert('Sharing is not supported in your browser. Please copy the link manually.');
    }
  };

  // Function to copy the invite link to clipboard
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(inviteLink);
      setLinkCopied(true);
      console.log('Link copied to clipboard');
    } catch (err) {
      console.error('Error copying link:', err);
      alert('Error copying the link. Please try again.');
    }

    setTimeout(() => setLinkCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <div className="game-container">
      {/* Main Content */}
      <div className='menu1'>
        <div className='profileImg'>
          <img src={profileImg} className='profile1' alt="Profile" />
          <span className='Imgtext'>Lv. 7</span>
          <span className='powerper'>5%</span>
        </div>
        <div className='HederCoin'>
          <div className='HeaderContain'>Coins per Hour</div>
          <img src={navlogo} alt="User Avatar" className="avatarcoin1" />
          <div className='percoin'>+225</div>
        </div>
        <IoMenu className='svg1' />
      </div>

      <div className="friends-container">
      <img className='image' src={gift} alt="Gift Icon" />
        <h1>Get 20% of your friends' income</h1>

        <div className="invite-section">
          <div className="reward-container">
            <img    src="https://img.icons8.com/color/48/000000/gift.png" alt="Gift Icon" />
            <div className="reward-text">
              <span className="reward-amount">+50 000</span>
              <span className="reward-note">For you and your friend</span>
            </div>
          </div>

          <div className="invite-actions">
            <button onClick={handleShare} className="invite-button">Invite a friend</button>
            <button onClick={handleCopyLink} className="copy-link-button">
              {linkCopied ? 'Link Copied!' : 'Copy Link'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FriendsPage;
