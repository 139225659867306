import '../App.css'; // Assuming your CSS is saved in App.css
import { BsShopWindow } from 'react-icons/bs';
import { FaUserFriends } from 'react-icons/fa';
import { SiSololearn } from 'react-icons/si';
import { MdSelfImprovement } from 'react-icons/md';
import { IoMenu } from "react-icons/io5";
import navlogo from "../bitslogo-removebg-preview.png";
import React, { useState } from 'react';
import profileImg from '../profile_img.jpeg';
import { useTranslation } from 'react-i18next'; // Import the translation hook
import i18n from '../i18n'; // Import your i18n configuration

const ImprovePage = () => {
  const [activePage, setActivePage] = useState('Improve');
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation(); // Use the translation function

  const [languageOpen, setLanguageOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');

  // List of languages (ISO 639-1 language names)
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'zh', name: 'Chinese' },
    { code: 'hi', name: 'Hindi' },
    { code: 'ar', name: 'Arabic' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'bn', name: 'Bengali' },
    { code: 'ru', name: 'Russian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'ko', name: 'Korean' },
    { code: 'it', name: 'Italian' },
    { code: 'nl', name: 'Dutch' },
    { code: 'tr', name: 'Turkish' },
    { code: 'sv', name: 'Swedish' },
    { code: 'pl', name: 'Polish' },
    { code: 'da', name: 'Danish' },
    { code: 'el', name: 'Greek' },
    { code: 'cs', name: 'Czech' },
    { code: 'he', name: 'Hebrew' },
    { code: 'id', name: 'Indonesian' },
    { code: 'th', name: 'Thai' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'ms', name: 'Malay' },
    { code: 'tl', name: 'Filipino' },
    { code: 'fi', name: 'Finnish' },
    { code: 'hu', name: 'Hungarian' },
    { code: 'no', name: 'Norwegian' },
    { code: 'uk', name: 'Ukrainian' },
    { code: 'ro', name: 'Romanian' },
    { code: 'ur', name: 'Urdu' },
    { code: 'sw', name: 'Swahili' },
    { code: 'ta', name: 'Tamil' },
    { code: 'te', name: 'Telugu' },
    { code: 'mr', name: 'Marathi' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'pa', name: 'Punjabi' },
    { code: 'fa', name: 'Persian' },
    { code: 'ps', name: 'Pashto' },
    { code: 'zu', name: 'Zulu' },
    { code: 'am', name: 'Amharic' },
    { code: 'ha', name: 'Hausa' },
    { code: 'so', name: 'Somali' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'lt', name: 'Lithuanian' },
    { code: 'lv', name: 'Latvian' },
    { code: 'et', name: 'Estonian' },
    { code: 'is', name: 'Icelandic' },
    { code: 'mk', name: 'Macedonian' }
  ];


  

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleLanguageDropdown = () => {
    setLanguageOpen(!languageOpen);
  };

  const handleLanguageSelect = (langCode, langName) => {
    setSelectedLanguage(langName);
    setLanguageOpen(false);
    i18n.changeLanguage(langCode); // Change the language
  };

  return (
    <div className="game-container">


      {/* Main Content */}
      <div className='menu1'>
        <div className='profileImg'>
          <img src={profileImg} alt="Profile" className='profile1' />
          <span className='Imgtext'>Lv. 7</span>
          <span className='powerper'>5%</span>
        </div>
        <div className='HederCoin'>
          <div className='HeaderContain'>{t("Coins per Hour")}</div>
          <img src={navlogo} alt="User Avatar" className="avatarcoin1" />
          <div className='percoin'>+225</div>
        </div>
        <IoMenu className="svg1" onClick={toggleMenu} />
        <div className={`side-menu ${menuOpen ? '' : 'open'}`}>
          <div className="menu-card">
            <ul>
              <li>{t('vibrate')}</li>
              <li>
                {t('language')}: {selectedLanguage}
                <button onClick={toggleLanguageDropdown} className="dropdown-btn">
                  {languageOpen ? '▲' : '▼'}
                </button>
                {languageOpen && (
                  <ul className="language-dropdown">
                    {languages.map(({ code, name }) => (
                      <li key={code} onClick={() => handleLanguageSelect(code, name)}>
                        {name}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li>
                <a href="https://t.me/+jM2JnbmvghU4Y2Fl" target="_blank" rel="noopener noreferrer">
                  {t('telegram')}
                </a>
              </li>
              <li>{t('support')}</li>
            </ul>
          </div>
        </div>

        <h4>Improve comming soon...</h4>

      </div>
    </div>
  );
};

export default ImprovePage;
