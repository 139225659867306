// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get, child ,onValue} from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAiMKkzD5snTZiFH53v3SYzD5djvEWCd9o",
  authDomain: "aird-70dc4.firebaseapp.com",
    databaseURL: "https://aird-70dc4-default-rtdb.firebaseio.com",
    projectId: "aird-70dc4",
    storageBucket: "aird-70dc4.firebasestorage.app",
    messagingSenderId: "994754392475",
    appId: "1:994754392475:web:dd58253f2fba577878713e",
      measurementId: "G-JQ6057NHK6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);


export { database, ref, set, get, child,onValue,  };
