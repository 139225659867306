import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Define the translation for each language
const resources = {
  en: {
    translation: {
      vibrate: 'Vibrate',
      language: 'Language',
      telegram: 'Our Telegram Channel',
      support: 'Support'
    }
  },
  es: {
    translation: {
      vibrate: 'Vibrar',
      language: 'Idioma',
      telegram: 'Nuestro Canal de Telegram',
      support: 'Soporte'
    }
  },
  fr: {
    translation: {
      vibrate: 'Vibrer',
      language: 'Langue',
      telegram: 'Notre chaîne Telegram',
      support: 'Support'
    }
  },
  // Add translations for other languages
  // For example, add resources for Hindi, Chinese, etc.
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React already escapes values
    }
  });

export default i18n;
